<script lang="ts" setup>
import type { MoleculesShareButtonProps } from './MoleculesShareButton.props'

const props = withDefaults(defineProps<MoleculesShareButtonProps>(), {
  copiedDuring: 5000,
})

const { breakpoint } = useProjectBreakpoint()
const { copied, copy } = useClipboard({ copiedDuring: props.copiedDuring })

const isShareApiSupported = ref(true)
const isMobile = computed(() => breakpoint.smallerOrEqual('md').value)

const openSharePanel = async () => {
  if (!isMobile.value || !isShareApiSupported.value) return
  if (navigator.share) {
    try {
      await navigator.share({
        url: props.url,
      })
    } catch {
      isShareApiSupported.value = false
    }
  } else {
    isShareApiSupported.value = false
  }
}
</script>

<template>
  <UtilsTooltip
    placement="bottom"
    :disabled="isMobile && isShareApiSupported"
    :class="[
      '[&>button]:flex [&>button]:items-center [&>button]:gap-x-2',
      { 'fix-icon-alignment': !isShareAll },
    ]"
  >
    <template #target>
      <!-- Button for sharing with label -->
      <AtomsCta
        v-if="isShareAll"
        :aria-label="$ts('sharePopup.share')"
        anatomy="link"
        @click-handler="openSharePanel"
      >
        <template #label>
          {{ buttonLabel ?? $ts('sharePopup.share') }}
        </template>
      </AtomsCta>
      <!-- Button for sharing with only icon  -->
      <span @click="openSharePanel">
        <DefaultIconsShare aria-hidden="true" />
      </span>
    </template>
    <template #content>
      <div class="flex w-[10.5rem] flex-col gap-y-4 md:w-64">
        <p v-if="popupTitle" class="text-medium-6 uppercase">
          {{ popupTitle }}
        </p>
        <ul class="space-y-2">
          <li class="*:flex *:items-center *:gap-x-2">
            <p v-if="copied" class="text-link-6 underline" aria-live="polite">
              <DefaultIconsCheck aria-hidden="true" />
              <span>{{ $ts('sharePopup.linkCopied') }}</span>
            </p>
            <div v-else>
              <DefaultIconsCopyLinkIcon aria-hidden="true" />
              <AtomsCta anatomy="link" @click="copy(url)">
                <template #label>
                  {{ $ts('sharePopup.copyLink') }}
                </template>
              </AtomsCta>
            </div>
          </li>
          <li v-if="!sharingIconsPdp" class="flex items-center gap-x-2">
            <DefaultIconsWhatsapp aria-hidden="true" />
            <MoleculesLinkWrapper
              anatomy="link"
              :to="`https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`"
              data-action="share/whatsapp/share"
              target="_blank"
            >
              <template #label>
                {{ $ts('sharePopup.whatsapp') }}
              </template>
            </MoleculesLinkWrapper>
          </li>
          <li v-if="!sharingIconsPdp" class="flex items-center gap-x-2">
            <DefaultIconsMail aria-hidden="true" />
            <MoleculesLinkWrapper
              anatomy="link"
              :to="`mailto:?body=${encodeURIComponent(url)}`"
            >
              <template #label>
                {{ $ts('sharePopup.email') }}
              </template>
            </MoleculesLinkWrapper>
          </li>
          <li v-if="sharingIconsPdp" class="flex items-center gap-x-2">
            <DefaultIconsFacebook aria-hidden="true" />
            <MoleculesLinkWrapper
              anatomy="link"
              :to="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`"
              target="_blank"
            >
              <template #label>
                {{ $ts('sharePopup.facebook') }}
              </template>
            </MoleculesLinkWrapper>
          </li>
          <li v-if="sharingIconsPdp" class="flex items-center gap-x-2">
            <DefaultIconsXIcon aria-hidden="true" />
            <MoleculesLinkWrapper
              anatomy="link"
              :to="`http://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`"
              target="_blank"
            >
              <template #label>
                {{ $ts('sharePopup.twitter') }}
              </template>
            </MoleculesLinkWrapper>
          </li>
        </ul>
      </div>
    </template>
  </UtilsTooltip>
</template>

<style scoped lang="scss">
.fix-icon-alignment.v-popper {
  @apply flex items-center;
}
</style>
